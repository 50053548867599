<template>
    <transition name="fade">
        <div class="remote" v-if="media" :class="[{mini:mini, small: small}, target]" v-show="type"  v-touch:swipe.right="prev" v-touch:swipe.left="next" v-touch:swipe.bottom="minify">
            <div class="bgcover" :style="{'background-color': media.color,  ...getBG() }" :class="type"></div>

            <div class="bar" >

                <button v-ripple class="action" @click="minify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </button>

                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00195 7.63027C5.00195 7.2989 5.27058 7.03027 5.60195 7.03027H18.4059C18.7372 7.03027 19.0059 7.2989 19.0059 7.63027V16.462C19.0059 16.7934 18.7372 17.062 18.4059 17.062H13.4413C13.2029 17.062 12.9876 16.9226 12.8854 16.7072C12.4625 15.815 11.2633 13.4465 9.81567 12C8.39582 10.5813 6.24338 9.55638 5.37987 9.17985C5.15312 9.08097 5.00195 8.85869 5.00195 8.61131V7.63027Z" fill="currentColor" /> </svg>
                    {{ media.did }}
                </span>

                <div class="actions">
                    <button class="action" @click="openActionDrawer" v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M12 12.5C12.2761 12.5 12.5 12.2761 12.5 12C12.5 11.7239 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.7239 11.5 12C11.5 12.2761 11.7239 12.5 12 12.5Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 18.5C12.2761 18.5 12.5 18.2761 12.5 18C12.5 17.7239 12.2761 17.5 12 17.5C11.7239 17.5 11.5 17.7239 11.5 18C11.5 18.2761 11.7239 18.5 12 18.5Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 6.5C12.2761 6.5 12.5 6.27614 12.5 6C12.5 5.72386 12.2761 5.5 12 5.5C11.7239 5.5 11.5 5.72386 11.5 6C11.5 6.27614 11.7239 6.5 12 6.5Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </button>

                    <transition name="fade">
                        <div class="drawer" v-if="actionDrawerOpen">
                            <li @click="fullscreen" v-ripple>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M9 9L4 4M4 4V8M4 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 9L20 4M20 4V8M20 4H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9 15L4 20M4 20V16M4 20H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 15L20 20M20 20V16M20 20H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                <span>Toogle Fullscreen</span>
                            </li>

                            <li @click="toggleAT" v-ripple>
                                <!--<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M14 14V6M14 14L20.1023 17.487C20.5023 17.7156 21 17.4268 21 16.9661V3.03391C21 2.57321 20.5023 2.28439 20.1023 2.51296L14 6M14 14H7C4.79086 14 3 12.2091 3 10V10C3 7.79086 4.79086 6 7 6H14" stroke="currentColor" stroke-width="1.5"/> <path d="M7.75716 19.3001L7 14H11L11.6772 18.7401C11.8476 19.9329 10.922 21 9.71716 21C8.73186 21 7.8965 20.2755 7.75716 19.3001Z" stroke="currentColor" stroke-width="1.5"/> </svg>-->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2 5H9M16 5H13.5M9 5L13.5 5M9 5V3M13.5 5C12.6795 7.73513 10.9612 10.3206 9 12.5929M4 17.5C5.58541 16.1411 7.376 14.4744 9 12.5929M9 12.5929C8 11.5 6.4 9.3 6 8.5M9 12.5929L12 15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.5 21L14.6429 18M21.5 21L20.3571 18M14.6429 18L17.5 10.5L20.3571 18M14.6429 18H20.3571" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                <span>Change Language</span>
                            </li>

                            <li @click="toggleCC" v-ripple>
                                <!--<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10.5 9.17071C10.1872 9.06015 9.85064 9 9.5 9C7.84315 9 6.5 10.3431 6.5 12C6.5 13.6569 7.84315 15 9.5 15C9.85064 15 10.1872 14.9398 10.5 14.8293" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.75 9.17071C16.4372 9.06015 16.1006 9 15.75 9C14.0931 9 12.75 10.3431 12.75 12C12.75 13.6569 14.0931 15 15.75 15C16.1006 15 16.4372 14.9398 16.75 14.8293" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>-->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M7.5 22C10.5376 22 13 19.5376 13 16.5C13 13.4624 10.5376 11 7.5 11C4.46243 11 2 13.4624 2 16.5C2 17.5018 2.26783 18.441 2.7358 19.25L2.275 21.725L4.75 21.2642C5.55898 21.7322 6.49821 22 7.5 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.2824 17.8978C16.2587 17.7405 17.1758 17.4065 18 16.9297L21.6 17.6L20.9297 14C21.6104 12.8233 22 11.4571 22 10C22 5.58172 18.4183 2 14 2C9.97262 2 6.64032 4.97598 6.08221 8.84884" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                <span>Closed Captions</span>
                            </li>
                            <li @click="getBack" v-ripple>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 20.5L12 12M12 12V16M12 12H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                <span>Go To Source</span>
                            </li>
                        </div>
                    </transition>

                    <div class="ccatch" @click="closeActionDrawer" v-if="actionDrawerOpen"> </div>
                </div>

            </div>

            <div @click="expand" class="imgs" :class="{single: (media.logos.length == 1), ufc: media.name.includes('UFC') }"  v-if="type == 'sport'" >
                <div class="img" v-for="(im, i) in media.logos"  :key="i">
                    <img :src="im"  @error="setFallbackImageUrl"/>
                </div>
            </div>

            <div class="splash" ref="splash" />

            <div class="image" v-if="showOrMovie" @click="expand">
                <img :src="media.logos[0]" />
            </div>

            <div class="image" v-if="type === 'live'" @click="expand">
                <img :src="media.logos[0]" />
            </div>

            <div class="image" v-if="type == 'game'" @click="expand">
                <img :src="media.logos[0]"/>
            </div>


            <div class="vip" v-if="vipMode">
                <div class="bg" @click="endVirtualInput"> </div>
            </div>

            <!-- <div v-if="media && !mini && media.type === 'od'" class="jump-ctrls"> </div> -->

            <Transition name="fade">
                <div class="range"  v-if="media && !mini && type == 'od'" :class="{disable: disableControls}" >
                    <div >

                        <div class="slider-container"
                            @touchstart.prevent="onTouchStart"
                            @touchmove.prevent="onTouchMove"
                            @touchend="onTouchEnd">
                            <div class="slider" :style="{ width: (currentTime / (media.duration || 0)) * 100 + '%' }"></div>
                            <div class="slider-thumb" :style="{ left: (currentTime / (media.duration || 0)) * 100 + '%' }"></div>
                        </div>

                        <div class="times" v-if="media.duration && currentTime">
                            <span for="slider">{{ formatTime(currentTime) }}</span>
                            <span for="slider">{{ formatTime((media.duration || 0) - currentTime) }}</span>
                        </div>
                    </div>

                </div>
            </Transition>

            <b v-if="media" @click="expand" >
                <div class="tiny safe" @click="prev" v-if="type == 'live'">
                    <svg style="transform: scaleX(-1)" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2.95592 5.70436C2.55976 5.41246 2 5.69531 2 6.1874V17.8126C2 18.3047 2.55976 18.5875 2.95592 18.2956L10.8445 12.483C11.1699 12.2432 11.1699 11.7568 10.8445 11.517L2.95592 5.70436Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.9559 5.70436C13.5598 5.41246 13 5.69531 13 6.1874V17.8126C13 18.3047 13.5598 18.5875 13.9559 18.2956L21.8445 12.483C22.1699 12.2432 22.1699 11.7568 21.8445 11.517L13.9559 5.70436Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>
                <p>  {{media.name || media.title}} </p>

                <div class="tiny safe" @click="next" v-if="type == 'live'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2.95592 5.70436C2.55976 5.41246 2 5.69531 2 6.1874V17.8126C2 18.3047 2.55976 18.5875 2.95592 18.2956L10.8445 12.483C11.1699 12.2432 11.1699 11.7568 10.8445 11.517L2.95592 5.70436Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.9559 5.70436C13.5598 5.41246 13 5.69531 13 6.1874V17.8126C13 18.3047 13.5598 18.5875 13.9559 18.2956L21.8445 12.483C22.1699 12.2432 22.1699 11.7568 21.8445 11.517L13.9559 5.70436Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>
            </b>

            <div class="controls" :class="{disable: disableControls, hasqueue: upNext.length > 0}" v-if="type !== 'game'">
                <div class="tiny safe im" @click="stop" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

                <div class="secondary" @click="rw" v-ripple>
                    <svg style="transform: scaleX(-1);" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M21.8883 13.5C21.1645 18.3113 17.013 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C16.1006 2 19.6248 4.46819 21.1679 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M17 8H21.4C21.7314 8 22 7.73137 22 7.4V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

                <div class="primary" @click="togglePlayPause" v-ripple>
                    <svg v-if="!playing" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    <svg v-if="playing" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6 18.4V5.6C6 5.26863 6.26863 5 6.6 5H9.4C9.73137 5 10 5.26863 10 5.6V18.4C10 18.7314 9.73137 19 9.4 19H6.6C6.26863 19 6 18.7314 6 18.4Z" stroke="currentColor" stroke-width="1.5"/> <path d="M14 18.4V5.6C14 5.26863 14.2686 5 14.6 5H17.4C17.7314 5 18 5.26863 18 5.6V18.4C18 18.7314 17.7314 19 17.4 19H14.6C14.2686 19 14 18.7314 14 18.4Z" stroke="currentColor" stroke-width="1.5"/> </svg>
                </div>

                <template v-if="showOrMovie" >

                    <div class="secondary"  v-if="progress < 10 && media.details.contentType === 'tv'" @click="skipIntro" v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M17 13H12V8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 3.5L7 2" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19 3.5L17 2" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 22C16.9706 22 21 17.9706 21 13C21 8.02944 16.9706 4 12 4C7.02944 4 3 8.02944 3 13C3 17.9706 7.02944 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </div>
                    <div class="secondary" v-else-if="progress > 90 && media.duration > 0" @click="() => { change(1) }"  v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M18 7V17" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6.97179 5.2672C6.57832 4.95657 6 5.23682 6 5.73813V18.2619C6 18.7632 6.57832 19.0434 6.97179 18.7328L14.9035 12.4709C15.2078 12.2307 15.2078 11.7693 14.9035 11.5291L6.97179 5.2672Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </div>

                    <div class="secondary" v-else @click="ff" v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M21.8883 13.5C21.1645 18.3113 17.013 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C16.1006 2 19.6248 4.46819 21.1679 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M17 8H21.4C21.7314 8 22 7.73137 22 7.4V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </div>

                </template>

                <div class="secondary" v-else @click="refresh" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M17.5 8C17.5 8 19 9.5 19 12C19 14.5 17.5 16 17.5 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.5 5C20.5 5 23 7.5 23 12C23 16.5 20.5 19 20.5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6.5 8C6.5 8 5 9.5 5 12C5 14.5 6.5 16 6.5 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 5C3.5 5 1 7.5 1 12C1 16.5 3.5 19 3.5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>


                <div class="tiny" @click="toggleMute" v-ripple>
                    <svg v-if="muted" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M17 14L19.0005 12M21 10L19.0005 12M19.0005 12L17 10M19.0005 12L21 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3 14V10C3 9.44772 3.44772 9 4 9H6.69722C6.89465 9 7.08766 8.94156 7.25192 8.83205L11.4453 6.03647C12.1099 5.59343 13 6.06982 13 6.86852V17.1315C13 17.9302 12.1099 18.4066 11.4453 17.9635L7.25192 15.1679C7.08766 15.0584 6.89465 15 6.69722 15H4C3.44772 15 3 14.5523 3 14Z" stroke="currentColor" stroke-width="1.5"/> </svg>
                    <svg v-if="!muted" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2 14V10C2 9.44772 2.44772 9 3 9H5.69722C5.89465 9 6.08766 8.94156 6.25192 8.83205L10.4453 6.03647C11.1099 5.59343 12 6.06982 12 6.86852V17.1315C12 17.9302 11.1099 18.4066 10.4453 17.9635L6.25192 15.1679C6.08766 15.0584 5.89465 15 5.69722 15H3C2.44772 15 2 14.5523 2 14Z" stroke="currentColor" stroke-width="1.5"/> <path d="M16.5 7.5C16.5 7.5 18 9 18 11.5C18 14 16.5 15.5 16.5 15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.5 4.5C19.5 4.5 22 7 22 11.5C22 16 19.5 18.5 19.5 18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>
            </div>


            <div class="controls" :class="{disable: disableControls}" v-else>

                <div class="tiny" @click="startVirtualInput" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M4 10V14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14V9C20 5.13401 16.866 2 13 2H12C7.58172 2 4 5.58172 4 10Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/> <path d="M12 2V8.4C12 8.73137 12.2686 9 12.6 9H20" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/> </svg>
                </div>

                <div class="secondary" @click="esc" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

                <div class="primary im" @click="stop" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

                <div class="secondary" @click="enter" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M13.25 19.25L16.75 15.75L13.25 12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.75 15.75H10.75C8.54086 15.75 6.75 13.9591 6.75 11.75V4.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

                <div class="tiny" @click="() => {}" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.6224 10.3954L18.5247 7.7448L20 6L18 4L16.2647 5.48295L13.5578 4.36974L12.9353 2H10.981L10.3491 4.40113L7.70441 5.51596L6 4L4 6L5.45337 7.78885L4.3725 10.4463L2 11V13L4.40111 13.6555L5.51575 16.2997L4 18L6 20L7.79116 18.5403L10.397 19.6123L11 22H13L13.6045 19.6132L16.2551 18.5155C16.6969 18.8313 18 20 18 20L20 18L18.5159 16.2494L19.6139 13.598L21.9999 12.9772L22 11L19.6224 10.3954Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>

            </div>


            <Flicking ref="flicking" :options="{ align: 'prev',mode: 'strict',deceleration: 75, count:1, horizontal: false, circularEnabled: false, bounce: 0, }" @move-end="$event => endNext($event)"  @move-start="$event => startNext($event)"  :plugins="plugins" v-if="upNext.length > 0" >
                <div class="space"></div>

                <div class="un" v-if="type === 'live'" :class="{upNextOpen}" :style="{'background-color': upNextOpen ? media.color.replace('0.8', '0.4') : 'rgba(0,0,0,0)' }">
                    <Transition name="fade">
                        <h3 v-if="!upNextOpen">
                            Up Next
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M22 17.5L18.5 20V15L22 17.5Z" fill="#fff" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 5L20 5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 11L20 11" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 17L14 17" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        </h3>
                    </Transition>

                    <Transition name="fade">
                        <div class="lip" v-if="upNextOpen">
                            <h3>
                                Your Queue
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M22 17.5L18.5 20V15L22 17.5Z" fill="#fff" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 5L20 5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 11L20 11" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 17L14 17" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                            </h3>

                            <div class="upnext">
                                <div class="queued">
                                    <div class="queue" v-ripple v-for="(x,i) in upNext" :key="i" @click="() => {change(i)}" :style="{'background-color': x.color}" :class="{tfs: media.details.country === '24/7', playing: (playerIndex === i)}">
                                        <div class="logo">
                                            <img :src="x.logos[0]"/>
                                        </div>
                                        <span> {{ x.name }} </span>
                                        <div class="play" v-if="playerIndex !== i" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11px" height="11px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                        </div>
                                        <div class="play" v-else> playing </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </Flicking>

        </div>
    </transition>
</template>

<script>
import io from 'socket.io-client'
import "@egjs/vue-flicking/dist/flicking.css";
import { Flicking } from "@egjs/vue-flicking";

export default {
  name: 'remote',

  props: {
    target: String,
    small: Boolean
  },

  components: {
    Flicking: Flicking
  },

  data: function(){
    return {
        timeSync: null,
        currentTime: 0,
        plugins: [],
        socket: null,
        vipMode: false,
        type: null,
        playing: true,
        playerIndex: -1,
        upNext: [],
        muted: false,
        mini: false,
        showOrMovie: false,
        disableControls: false,
        currentColor: '#333',
        upNextOpen: false,
        media: null,
        actionDrawerOpen: false,
        isDragging: false,
        test: this.debounce(this.seekTo, 500)

    }
  },
  computed: {
    progress(){
        return (this.currentTime / (this.media.duration || 0)) * 100
    }
  },
  methods: {
    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };

    },

    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);

      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      return formattedTime;
    },

    onTouchStart(event) {
      this.isDragging = true;
      this.handleTouch(event);
    },
    onTouchMove(event) {
      if (this.isDragging) {
        this.handleTouch(event);
      }
    },
    onTouchEnd() {
      this.isDragging = false;
    },
    handleTouch(event) {
      const container = event.target.getBoundingClientRect();
      const clientX = event.touches[0].clientX - container.left;
      const percentage = clientX / container.width;
      this.currentTime = Math.min(this.media.duration, Math.max(0, (percentage) * (this.media.duration)))
            this.test()
    },

    getBG(){
        if(this.type == 'od' && this.media && this.media.logos[1]){
            return { 'background-image': `url(${this.media.logos[1]})` }
        }
        if(this.media && this.type === 'game'){
            return {'background-image': `url(${this.media.logos[0]})` }
        }
        return {}
    },
    openActionDrawer(){ this.actionDrawerOpen = !this.actionDrawerOpen },
    closeActionDrawer(){ this.actionDrawerOpen  = false },
    startNext(event){
        if(event.direction === 'NEXT'){
            document.querySelector('.remote .flicking-viewport').style.zIndex = 9
            document.querySelector('.remote .flicking-viewport').style.top = '22.5vh'
            setTimeout(() => {
                this.upNextOpen = true
            }, 300)

        }

        if(this.$refs.flicking.currentPanel.index === 1 && event.direction === 'PREV'){
            setTimeout(() => {
                this.upNextOpen = false
            }, 200);
            document.querySelector('.remote .flicking-viewport').style.background = 'rgba(0,0,0,0)';
        }
    },

    endNext(event){
        if(this.$refs.flicking.currentPanel.index === 0 && event.direction === 'NEXT'){
            setTimeout(() => {
                document.querySelector('.remote .flicking-viewport').style.background = 'rgba(0,0,0,0.3)';
            }, 225)
            this.upNextOpen = true
        }
        if(this.$refs.flicking.currentPanel.index === 1 && event.direction === 'PREV'){
            document.querySelector('.remote .flicking-viewport').style.zIndex = -1
            document.querySelector('.remote .flicking-viewport').style.background = 'rgba(0,0,0,0)';
            document.querySelector('.remote .flicking-viewport').style.top = '0'
            this.upNextOpen = false
        }
    },
    ev(){
       return process.env.VUE_APP_SERVER
    },

    checkShowOrMovie(){
        this.showOrMovie = this.type == 'od'
    },

    expand(){
        if(this.mini && !this.small){
            this.mini = false;
            this.$parent.open = false;
            document.querySelector('html').classList.add('remoteActive')
        }

    },

    minify(){
        if(this.upNextOpen){return}

        this.mini = true;
        this.$parent.open = true;
        this.closeActionDrawer()
        document.querySelector('html').classList.remove('remoteActive')
    },


    stop(){
        document.querySelector('html').classList = ''
        this.$parent.open = open;
        this.currentColor = '#333';
        fetch(process.env.VUE_APP_SERVER + `/home?did=${window.did}`)
        this.type = null
        navigator.mediaSession.playbackState = 'none'
        window.did = null
        sessionStorage.setItem('lastDid', '')
    },

    moveToSecondary(){
        fetch(process.env.VUE_APP_SERVER + `/moveToSecondary?did=${window.did}&id=${this.media.id}`, {method: 'GET'})
        navigator.vibrate(50)
    },

    moveToPrimary(){
        fetch(process.env.VUE_APP_SERVER + `/moveToPrimary?did=${window.did}&id=${this.media.id}`, {method: 'GET'})
        navigator.vibrate(50)
    },

    refresh(){
        fetch(process.env.VUE_APP_SERVER + `/jump?did=${window.did}`)
        navigator.vibrate(50)
    },

    toggleAT(){
        fetch(process.env.VUE_APP_SERVER + `/toggleAudioTrack?did=${window.did}`)
        navigator.vibrate(50)
    },
    toggleCC(){
        fetch(process.env.VUE_APP_SERVER + `/toggleCaptions?did=${window.did}`)
        navigator.vibrate(50)
    },

    toggleMute(){
        this.muted = !this.muted
        fetch(process.env.VUE_APP_SERVER + `/toggleMute?did=${window.did}`)
        navigator.vibrate(50)
    },

    togglePlayPause(){
        this.playing = !this.playing
        fetch(process.env.VUE_APP_SERVER + `/togglePlay?did=${window.did}`)
        navigator.vibrate(50)
    },

    getBack(){
        window.location.href = this.media.ref
        navigator.vibrate(50)
    },

    throttle (callback, limit) {
        var waiting = false;                      // Initially, we're not waiting
        return function () {                      // We return a throttled function
            if (!waiting) {                       // If we're not waiting
                callback.apply(this, arguments);  // Execute users function
                waiting = true;                   // Prevent future invocations
                setTimeout(function () {          // After a period of time
                    waiting = false;              // And allow future invocations
                }, limit);
            }
        }
    },

    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    skipIntro(){
        fetch(process.env.VUE_APP_SERVER + `/tab?did=${window.did}`)
    },

    change(i){
        this.currentTime = 0
        this.media.duration = 0
        if(i !== undefined){
            this.playerIndex  = i
        }
        fetch(process.env.VUE_APP_SERVER + `/queue?did=${window.did}&i=${this.playerIndex}`)
        this.sleep(100)
        navigator.vibrate(50)
        return
    },
    next(){
        if(this.media.type === 'live'){
            if(this.upNextOpen){return}
            if(this.playerIndex < 54){

                this.playerIndex = this.playerIndex + 1
                this.change()
                // this.throttle(, 1)
            }
        }
    },
    prev(){
        if(this.media.type === 'live'){
            if(this.upNextOpen){return}
            if(this.playerIndex > 0){
                this.playerIndex = this.playerIndex - 1
                this.change()
                // this.throttle(this.change(), 1)
            }
        }
    },

    ff(){
        fetch(process.env.VUE_APP_SERVER + `/ff?did=${window.did}`)
        navigator.vibrate(50)
    },
    rw(){
        fetch(process.env.VUE_APP_SERVER + `/rw?did=${window.did}`)
        navigator.vibrate(50)
    },

    fullscreen(){
        fetch(process.env.VUE_APP_SERVER + `/fullscreen?did=${window.did}`)
        navigator.vibrate(50)

    },
    esc(){
        fetch(process.env.VUE_APP_SERVER + `/esc?did=${window.did}`)
        navigator.vibrate(50)
    },
    startVirtualInput(){
        fetch(process.env.VUE_APP_SERVER + `/startVirtualInput?did=${window.did}`)
        this.vipMode = true
        setTimeout(() => {
            window.open('http://wr2.studio14a.com:5566/',"_blank")
        }, 1500)
        navigator.vibrate(50)
    },
    endVirtualInput(){
        fetch(process.env.VUE_APP_SERVER + `/endVirtualInput?did=${window.did}`)
        this.vipMode = false
        navigator.vibrate(50)
    },

    seekTo(){
        fetch(process.env.VUE_APP_SERVER + `/seek?did=${window.did}&t=${this.currentTime}`)
    },

    enter(){
        fetch(process.env.VUE_APP_SERVER + `/enter?did=${window.did}`)
        navigator.vibrate(50)
    },


    setFallbackImageUrl(event) {
        event.target.src = ''
    },

    getRemotes(){
      if(!window.did){return}

      fetch(process.env.VUE_APP_SERVER + '/now_playing?did='+window.did, {method: 'GET', mode: 'cors', cache: 'no-cache', redirect: 'follow', referrerPolicy: 'no-referrer'}).then(response => response.json()).then((data) => {
            this.updateRemote(data)
      })
    },

    updateRemote(x){
          if(!x){
              document.querySelector('html').classList.remote('remoteOpen')
              return
          }
          if(x.media !== null && x.media.type !== undefined){
                document.querySelector('html').classList.add('remoteOpen')
                this.currentTime = x.media.time

                if(!this.type){
                    this.mini = true
                    this.$parent.open = true
                }

                this.type = x.media.type

                this.checkShowOrMovie()
                this.media = x.media
                this.upNext = x.upNext
                this.playing = x.playing
                this.muted = x.muted
                this.currentColor = x.media.color
                if(this.upNext){
                    this.playerIndex = this.upNext.findIndex((y) => { return y.id === x.media.id })
                }
          }else{
              document.querySelector('html').classList.add('remoteOpen')
              this.type = null
              this.$parent.open = true
          }

      }

},

beforeUnmount: function(){
        this.socket.disconnect()
        this.socket = null
  },

 mounted: function () {
        if (!this.socket) { this.socket = io(process.env.VUE_APP_SERVER + '/') }

        this.socket.on('gameStream', (x) => { this.$root.$emit('gameStream', x); })

        this.socket.on('newRemote', (x) => {
            if (window.did !== x.did) { return }
            this.updateRemote(x)
        })
        this.socket.on('mediaReady', (x) => {
            if (window.did !== x.did) { return }
            this.disableControls = false
            this.media.duration = x.duration
        })

        this.socket.on('mediaNotReady', (x) => {
            if (window.did !== x.did) { return }
            this.disableControls = true
        })

        this.socket.on('time', (x) => {
            if (window.did !== x.did) { return }
            this.currentTime = x.time
        })


        this.$root.$on('getRemote', this.getRemotes)
        this.getRemotes()
    }

}
</script>

<style scoped lang="scss">
.remote{
    display: flex;
    height: 102vh;
    width: 100%;
    border-radius: 9px;
    will-change: auto;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    pointer-events: all;
    position: relative;
    background: rgba(14, 13, 13, 0.8);
    backdrop-filter: blur(10px);
    .image{
        max-width: 300px;
    }
    .action{
        margin-left: 5px;
        padding: 5px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: rgba(black, 0);
        border: none;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        &.long{
            width: auto;
            color: white;
            padding-right: 15px;
            svg{
                padding: 0 10px;
            }
        }
        svg{
            color: white;
        }
    }

    .actions{
        display: flex;
    }

    .ccatch{
        position:absolute;
        width: 100vw;
        height: 100vh;
        bottom: 0;
        right: 0;
        z-index: 999;
    }

    .space{
        width: 100vw;
        height: 90vh;
        pointer-events: none;
    }
    .flicking-viewport {
        position: absolute;
        width: 100vw;
        overflow: hidden;
        height: 100vh;
        top: 2vh;
        left: 0;
        z-index: -1;
        border-radius: 15px;
        &.queueopen{
            z-index: 10;
            background-color: black;
        }
    }

    .un{
        width: 100vw;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px -9px 10px 0px rgba(0, 0, 0, 0.35);
        text-transform: uppercase;
        border-radius: 15px;
        backdrop-filter: blur(20px);
        font-weight: bold;
        h3{
            padding: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 3px;
            justify-content: space-between;
        }

        .upnext{
            display: flex;
            flex-direction: column;

        }
        .queued{
            display: grid;
            grid-template-columns: repeat(20, 1fr);
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 5px;
            grid-row-gap: 5px;
            overflow: hidden;
            flex-direction: row;
            width: 100vw;
            height: 100%;
            padding: 0 40px;
            box-sizing: border-box;
            overflow: scroll;
            text-transform: none;
            font-weight: normal;
            letter-spacing: normal;
            font-size: 11px;
            justify-content: center;
            .queue{
                align-items: center;
                border-bottom: 1px solid white;
                padding: 0px 20px;
                border-radius: 10px;
                opacity: 0.8;
                width: 100px;
                max-width: 100px;
                border-bottom-left-radius: 10px;
                position: relative;
                overflow: hidden;
                &:first-child{ margin-left: 2570px; }
                &:nth-child(21){ margin-left: 2570px; }
                &:nth-child(41){ margin-left: 2570px; }
                &.tfs{
                    .logo{
                        height: 75px;
                        border-radius: 9px;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        padding: 0;
                        overflow: hidden;
                        img{
                            width: 100%;

                        }

                    }
                    span{
                        padding-bottom: 0;
                    }
                }

                span{
                    flex-grow: 1;
                    padding-bottom: 20px;
                    display: block;
                    opacity: 0.7;
                    text-align: center;
                }
                .play{
                    background-color: rgba(white, 0.3);
                    font-size: 9px;
                    padding: 5px;
                    opacity: 0.5;
                    border-radius: 3px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    svg{
                        padding: 0;
                    }
                }

                .logo{
                    width: 100px;
                    height: 90px;
                    padding: 0;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;


                    img{
                        width: 60%;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }
        }
        svg{
            padding-left: 10px;
        }
    }




    .drawer{
        position: fixed;
        top: calc(10% + 20px);
        right: calc(10% - 20px);
        z-index: 9999;
        width: 175px;
        background-color: rgba(14, 14, 14, 0.85);
        backdrop-filter: blur(10px);
        border-radius: 9px;
        box-shadow: 2px -2px 7px 1px rgba(3, 3, 3, 0.46);
        padding: 5px 0;
        border-radius: 15px;
        overflow: visible;

        li{
            position: relative;
            text-decoration: none;
            padding: 20px 5px;
            list-style: none;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            border-bottom: 1px solid black;

            &:last-of-type{
                border-bottom: 0px solid black;
            }
            span{
                padding: 0 10px;
                font-size: 12px;
            }
            svg{
                padding: 0 10px;
                padding-bottom: 10px;
                width: 30px;
            }
        }
    }

    b{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          width: 100%;
        }
        svg{
            position: relative;
            top: -50px;
            width: 20px!important;
            height: 20px!important;
            padding: 20px;
            opacity: 0.75;
            border-radius: 50%;
            background-color: rgba(black, 0.2);
        }
    }
    &.mini{
        height: 60px;
        margin: 0;
        bottom: -5px;
        overflow: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        margin: 5px 10px;
        .flicking-viewport {
            pointer-events: none;
        }
        .bar, .un{
            display: none;
        }
        b{
            padding-right: 10px;
            display: block;
            font-size: 9pt;
            flex-grow: 1;
            p{
                text-align: center;
            }
        }
        .imgs{
            padding: 5px;
            //margin: 0 10px;
            img{
                width: 25px!important;
                margin: 0px 5px;
            }
            &.single{
                width: 50px!important;
            }
        }
        .image{
            overflow: hidden;
            margin: 0 20px;
            width: 60px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.small{
                width: 40px;
                top: 0;
                img{
                    display: block;
                }
            }
        }
        .controls {
            width: 60px;

            .primary{
                padding: 5px;
                margin-top: -5px;
                width: 20px;
                height: 20px;
                float: left;
                svg{
                    height: 20px;
                    padding: 2px;
                    box-sizing: border-box;
                }

            }
        }
        .secondary, .tiny{
            display: none;
        }
        &.small{
            p, .controls{
                display: none
            }
        }
    }

    &:not(.mini){
        position: fixed;
        width: 100vw;
        height: 101vh;
        transform: translateY(calc(-100% + 55px));
        border-radius: 0;
        transition-duration: 0.3s;

        b{
            text-align: center;
            min-width: 100%;
        }
    }

    .bar{
        display: flex;
        width: 90%;
        margin-top: -40px;
        flex-direction: row;
        justify-content: space-between;
        vertical-align: space-between;
        >span{
            svg{
                margin-right: 10px;

            }
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;

        }
    }

    .imgs{
        display: flex;
        background-color: transparent!important;
        flex-directon: row;
        position: relative;
        border-radius: 8px;
        padding: 50px 30px;
        img{
            width: 100px;
            margin: 20px;
        }
        &.ufc{
            img{
                max-width: 125px;
            }
        }
        &.single{
            img{
                width: 80vw;
            }
        }
    }

    .splash{
        position: fixed;
        top: -40px;
        left: -40px;
        min-width: calc(100% + 50px);
        height: calc(100% + 50px);
        opacity: 0.8;
        pointer-events: none;
        z-index: -1;
        background-size: 400% 400%;
        overflow: hidden;
        animation: gradient 15s ease infinite;
        .content{
            width: 100%;
        }
        :after{
            content: ' ';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: #111;
            opacity: 0.2;
            z-index: -1;
        }

    }

    &.primary{
        .splash{
            background-color: #333;
        }
    }

    &.secondary{
        .splash{
            background-color: #000;
        }
    }

    @keyframes gradient { 0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .image{
        width: 60vw;
        height: auto;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;
        background-color: transparent!important;


        &.small{
            width: 30vw;
            height: auto;
        }
        img{
            object-fit: contain;
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .controls{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &.hasqueue{
            padding-bottom: 5vh;
        }
        &.disable{
            > div:not(.safe){
                pointer-events: none;
                opacity: 0.2;
            }
        }

        .secondary{
            margin-top: 80px;
            border-radius: 50%;
        }

        .tiny{
            margin-top: -60px;
            border-radius: 50%;
        }

        .primary{
            margin-top: -20px;
            padding: 20px;
            text-align: center;
            svg{
                margin-top: 3px;
                margin-left: 1px;
            }
            vertical-align: middle;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background-color: rgba(white, 0.2);
        }
        .secondary{}
        .tiny{}
    }

}
.mini-bar{
    display: flex;
    margin-top: 10px;
    align-items: center;
    b{
        width: 55vw!important;
    }

    .primary{
        padding: 5px;
        border-radius: 50%;
        margin-top: -5px;
        width: 20px;
        height: 20px;
        background-color: rgba(white, 0.2);
        svg{
            margin: 0;
        }
    }
}
.remote.mini{
    &::before{
            opacity: 0;
        }
    .primary{
        padding: 5px;
        border-radius: 50%;
        margin-top: -5px;
        width: 20px;
        height: 20px;
        background-color: rgba(white, 0.2);
        svg{
            margin: 0;
        }
    }
    .controls.hasqueue{
        padding-bottom: 0;
    }
}

svg{
    width: 20px;
}
.vip{
    position: absolute;
    top: 0;
    z-index: 90;
    .bg{
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.1);
        backdrop-filter: blur(20px);
        z-index: 1;
    }
    .box{
        top: 0;
        width: 150px;
        height: 150px;
        z-index: 2;
        background-color: rgba(black, 0.9);
        border-radius: 8px;
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            line-height: 150px;
            font-size: 20px;
            text-align: center;
        }
    }
    input{
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
.bgcover{
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    right: -5%;
    z-index: -1;
    opacity: 0.55;
    background: black;
    pointer-events: none;
    &.od, &.game{
        filter: blur(20px);
        background-size: cover;
        background-position: center;
    }
}
.seek{
    width: 85%;
}

.disable > div:not(.im) {
    opacity: 0.25;
    pointer-events: none;
}

.jump-ctrls{
    position: absolute;
    bottom: 10px;
    div{
        padding: 10px 50px;
        display: flex;
        align-items: center;
        border: 1px solid black;
        background-color: rgba(black, 0.45);
        backdrop-filter: blur(20px);
        z-index: 2;
        border-radius: 5px;
        svg{
            padding-left: 10px;
        }
    }

}

.slider-container {
  width: 80vw;
  margin: 0 auto;
  position: relative;
  height: 5px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(black, 0.3);
}

.slider {
  height: 5px;
  background: rgba(#3498db, 0.5);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.times{
    width: 100%;
    pointer-events: none;
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    font-weight: bold;
    padding-top: 10px;
}

.slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  pointer-events: none;
  border: 2px solid #2980b9;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
